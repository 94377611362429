import React from 'react';
import { withNamespaces } from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const GuestInfoDesktopNav = ({ t , categories, activeKey, onItemClick }) => {

  return (
    <div className={'row d-md-block d-xl-block d-none'}>
      <div className={'col-12 mt-4 guestinfo-item-container-desktop'}>
        {Object.keys(categories).map((cat, i) => {
          return (
            <div data-itemcat={cat} key={cat}
                 className={'guestinfo-item-desktop p-2 text-nowrap text-center ' + (activeKey !== cat ? 'guestinfo-item-desktop-small' : '')}
                 title={t('app.guestinfo.desktop.category.' + categories[cat])}
                 onClick={onItemClick}
            >
              <div className={'float-left d-flex justify-content-center align-items-center guestinfo-icon-box'}>
                <FontAwesomeIcon icon={['fad', cat]} className={'text-darksecondary guestinfo-icon'}/>
              </div>
              <span className={'guestinfo-item-desktop-categorytext pl-2 text-darksecondary'}>{t(
                'app.guestinfo.desktop.category.' + categories[cat])}</span>
            </div>
          );
        })
        }
      </div>
    </div>
  );

};

export default withNamespaces()(GuestInfoDesktopNav);
